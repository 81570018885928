/* EditModal.css */

.modal-edit .modal-dialog .row {
    margin-right: 0;
    margin-left: 0;
}

.modal-edit .modal-dialog .modal-header,
.modal-edit .modal-dialog .modal-footer {
    padding: 12px 15px;
}

.modal-edit .modal-dialog .modal-body {
    padding: 0.85rem;
    /*max-height: 90%;
    max-width: 90%;*/
}

    .modal-edit .modal-dialog .modal-body .form-group {
        margin-bottom: 2px;
        padding: 0 5px;
    }

        .modal-edit .modal-dialog .modal-body .form-group .control-label {
            margin-top: 2px;
            margin-bottom: 0;
        }

        .modal-edit .modal-dialog .modal-body .form-group .form-control {
            padding: 4px 9px;
            height: 28px;
            font-size: small;
        }

        .modal-edit .modal-dialog .modal-body .form-group textarea.form-control {
            height: auto;
            resize: vertical;
        }

        .modal-edit .modal-dialog .modal-body .form-group .invalid-feedback {
            display: none;
            width: 100%;
            margin-top: 1px;
            margin-bottom: 1px;
            font-size: 80%;
            color: #dc3545;
        }

.modal-edit .modal-dialog .was-validated .form-control:invalid ~ .invalid-feedback {
    display: block;
}

.modal-edit .modal-dialog .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: -3px;
}

.modal-edit .modal-dialog .form-control .select2-container {
    width: 103% !important;
    font-size: inherit;
    height: 17.6px;
    top: -6px;
    left: -4px;
    padding-top: 2px;
}


/* validation css - start */
.modal-edit .modal-dialog .has-error .control-label {
    color: inherit;
}

.modal-edit .modal-dialog .form-control.is-valid,
.modal-edit .modal-dialog .was-validated .form-control:valid,
.modal-edit .modal-dialog .form-control.is-invalid,
.modal-edit .modal-dialog .was-validated .form-control:invalid {
    background-image:none;
}

.modal-edit .modal-dialog .was-validated .form-control.select2-control,
.modal-edit .modal-dialog .was-validated .form-control.select2-control:valid {
    border-color: #28a745; /*green*/
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

    .modal-edit .modal-dialog .was-validated .form-control.select2-control:valid:focus {
        border-color: #28a745; /*green*/
        box-shadow: 0 0 0 0.2rem rgba(40,167,69,.25);
    }


.modal-edit .modal-dialog .has-error .form-control.select2-control:focus {
    border-color: #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)
}

.modal-edit .modal-dialog .was-validated .has-error .form-control.select2-control:focus:invalid {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
}

.modal-edit .modal-dialog .has-error .form-control.select2-control {
    border-color: #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.modal-edit .modal-dialog .was-validated .has-error .form-control.select2-control:invalid {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

/* validation css - end */
