/*NavMenu.css*/

a.nav-link.dropdown-toggle.dropdown-toggle-split {
    padding-left: 0;
    margin-left: -3px;
}

.navbar .navbar-test-env {
    background-color: firebrick;
    border-radius: 5px;
    padding: 0px 6px;
}

nav.main-navbar i.fa {
    margin-left: 0;
}


/* BS3 - start
.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items * /
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: white;
}

.navbar {
    border-radius: 0;
    border-width: 0;
    margin-bottom:0;
}

/*stelian-start* /

    .navbar .nav-dropdown .dropdown-toggle {
        padding-left: 0px;
        padding-right: 10px;
    }
        .navbar .nav-dropdown .dropdown-toggle .caret {
            border-top: 7px dashed;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
        }

/* for auto expanded dropdown
.dropdown:hover .dropdown-content {
    display: block;
}
* /

/*stelian-end* /
BS3 - end */

/*
@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar * /
    .navbar {
        height: 100%;
        width: calc(25% - 20px);
    }
    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
    }
    .navbar-header {
        float: none;
    }
    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }
    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }
    .navbar .container-fluid .navbar-brand {
        margin: 0;
    }
    .navbar ul {
        float: none;
    }
    .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }
    .navbar li a {
        padding: 10px 16px;
        border-radius: 4px;
    }
    .navbar a {
        /* If a menu item's text is too long, truncate it * /
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
*/