/*grid.component.scss*/
@import url(../assets/lib/jquery-ui-1.11.3.custom.min.css);
/*#D6D7D6; #bfbfbf*/
@import url(../assets/slickgrid-es6/src/slick.grid.css);
@import url(../assets/slickgrid-es6/src/slick-default-theme.css);
@import url(../assets/slickgrid-es6/plugins/slick.spreadsheet.plugins/css/plugins-common.css);
@import url(../assets/slickgrid-es6/plugins/slick.spreadsheet.plugins/css/plugins-gdoc-style.css);
@import url(../../node_modules/font-awesome/css/font-awesome.css);
@import url(~flag-icon-css/css/flag-icon.min.css);
.slickgrid-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  font-size: 14px;
  border: 1px solid #D6D7D6;
  /* moved outside slickgrid-container
    .cell-checkmark {
        content: url('../images/tick.png');
        background: url('../images/tick.png') no-repeat;
    }
    */
  /* Menu button */
  /*
    .header-overlay, .cell-overlay, .selection-cell-overlay {
        display: block;
        position: absolute;
        z-index: 999;
    }

    .cell-overlay, .selection-cell-overlay {
        background-color: #217346;
    }


    .handle-overlay {
        border: 1px solid white;
        padding: 2px;
        background-color: #217346;
        position: absolute;
        width: 1px;
        z-index: 1000;
        cursor: crosshair;
    }

    .overlay-handle-decorator {
        border: 2px solid #217346;
        z-index: 1000;
    }
    /* Menu button * /
    .slick-header-menubutton {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 14px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url(../images/down.gif);
        cursor: pointer;
        display: none;
        border-left: thin ridge silver;
    }

    .slick-header-column > .slick-header-menubutton,
    .slick-header-column-active .slick-header-menubutton {
        display: inline-block;
    }
    */ }
  .slickgrid-container .slick-viewport, .slickgrid-container .slick-header, .slickgrid-container .slick-headerrow {
    border: none; }
  .slickgrid-container .slick-editable {
    border-color: #D6D7D6;
    box-shadow: -1px 0 0 #D6D7D6; }
  .slickgrid-container .slick-uneditable {
    border-left: 1px solid #D6D7D6;
    background: white;
    text-overflow: initial; }
  .slickgrid-container .slick-row .slick-cell {
    padding-top: 5px; }
  .slickgrid-container .slick-row > slick-cell.frozen:last-of-type:not(.r0) {
    border-right: 1px solid darkblue; }
  .slickgrid-container .slick-row.active {
    /*background: rgba(55, 133, 183, .3);*/
    /*
        .slick-cell.active.frozen {
            border-right-color: $grid-frozen-separator-border-color;
        }
        */ }
    .slickgrid-container .slick-row.active .slick-cell.active {
      /*background: initial;*/
      padding-top: 4px;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: #d6d7d6;
      border-bottom-color: #d6d7d6;
      /* Slick.Editors.Text, Slick.Editors.Date */
      /* Slick.Editors.Checkbox */ }
      .slickgrid-container .slick-row.active .slick-cell.active input.editor-text {
        width: 100%;
        height: 100%;
        border: 0;
        margin: 0;
        margin-top: 1.5px;
        margin-right: -2.5px;
        background: transparent;
        outline: 0;
        padding: 0 0 0 3px; }
      .slickgrid-container .slick-row.active .slick-cell.active .ui-datepicker-trigger {
        background: url("../images/calendar.gif") top left no-repeat;
        width: 16px;
        height: 16px;
        margin-top: -1px;
        padding: 0;
        vertical-align: top;
        color: transparent;
        /*to hide the 3 dots after image in chrome*/ }
      .slickgrid-container .slick-row.active .slick-cell.active input.editor-checkbox {
        margin: 0;
        height: 100%;
        padding: 0;
        border: 0; }
  .slickgrid-container .cell-checkbox {
    text-align: center; }
  .slickgrid-container .slick-row .slick-cell.cell-selection {
    border-right-color: silver;
    border-right-style: solid;
    background: #f5f5f5;
    color: gray;
    text-align: right;
    /*font-size: 10px;*/ }
  .slickgrid-container .slick-row .slick-cell.selected,
  .slickgrid-container .slick-row.odd .slick-cell.selected {
    background-color: inherit; }
  .slickgrid-container .slick-row.odd {
    background-color: #fafafa; }
  .slickgrid-container .slick-cell.selected:not(.editable):not(.copied):not(.active)::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.08;
    background-color: #005eff;
    /*#141414;*/
    /*rgba(196, 226, 255, 0.3);*/ }
  .slickgrid-container .slick-cell.copied:not(.editable)::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    background-color: #005eff;
    /*rgba(196, 226, 255, 0.3);*/
    /*-webkit-transition: 0.2s background;*/ }
  .slickgrid-container .slick-row.even .slick-cell.changed,
  .slickgrid-container .slick-row.odd .slick-cell.changed {
    background-color: #ffecec;
    /*rgba(255, 235, 153, 0.5)*/
    /*(255, 153, 128, 0.3) ;*/
    /*rgb(255, 209, 179);*/ }
  .slickgrid-container .slick-cell.editable {
    background-color: inherit; }
  .slickgrid-container .slick-cell.bulk-invalid {
    border-color: red;
    border-style: solid; }
  .slickgrid-container .slick-header-column.ui-state-default {
    line-height: 15px;
    overflow: visible; }
  .slickgrid-container .slick-resizable-handle {
    width: 8px;
    right: -6px;
    z-index: 99; }
  .slickgrid-container .slick-columnpicker {
    font-size: 11px; }
    .slickgrid-container .slick-columnpicker label {
      margin-bottom: inherit; }
  .slickgrid-container .slick-header-column.selected-header,
  .slickgrid-container .slick-row .slick-cell.cell-selection.selected-header {
    background-color: #e1e1e1; }
  .slickgrid-container .slick-headerrow-column {
    background: #6fbf93;
    /*#87ceeb;*/
    text-overflow: clip;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font: 9pt arial, sans-serif; }
    .slickgrid-container .slick-headerrow-column input {
      font: 9pt arial, sans-serif;
      outline: 0 none; }
  .slickgrid-container .slick-headerrow-column.ui-state-default {
    padding: 1px 1px 2px 0px; }
  .slickgrid-container .slick-headerrow-column.l0.r0 {
    background-color: #f5f5f5;
    border-bottom: 1px solid #D6D7D6; }
  .slickgrid-container .slick-headerrow-column.l1.r1 {
    padding-left: 1px; }
  .slickgrid-container .slick-headerrow-column input {
    margin: 0;
    padding: 0px 3px;
    width: 100%;
    height: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: none; }
  .slickgrid-container .slick-header-menubutton {
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    background-image: url(../images/down.png); }
  .slickgrid-container .slick-header-menubutton.filtered {
    background-color: #a4e5c1;
    background-image: url(../images/filter.png); }
  .slickgrid-container .slick-sort-indicator {
    margin-top: -8px; }
  .slickgrid-container .slick-sort-indicator-desc {
    background: url(../images/sort-desc.gif); }
  .slickgrid-container .slick-sort-indicator-asc {
    background: url(../images/sort-asc.gif); }

.cell-checkmark {
  content: url("../images/tick.png");
  background: url("../images/tick.png") no-repeat; }

/* Menu */
.slick-header-menu {
  position: absolute;
  display: inline-block;
  margin: 0;
  padding: 2px;
  cursor: default;
  background-color: white;
  z-index: 1001;
  min-width: 150px;
  border: 1px solid silver;
  font-size: 11px; }

/* Menu items */
.slick-header-menuitem {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer; }

.slick-header-menuicon-sort-asc {
  background-image: url("../images/sort-asc.png"); }

.slick-header-menuicon-sort-desc {
  background-image: url("../images/sort-desc.png"); }

.slick-header-menuicon-cust-flt {
  background-image: url("../images/filter.png"); }

/* it's defined in plugins-common.css
.slick-header-menuicon {
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
    width: 16px;
}

.slick-header-menucontent {
    display: inline-block;
    vertical-align: middle;
}
*/
.slick-header-menu .filter {
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid #D3D3D3;
  border-bottom: 1px solid #D3D3D3;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 180px;
  white-space: nowrap; }
  .slick-header-menu .filter .vrow {
    width: 100%; }
    .slick-header-menu .filter .vrow:hover {
      background-color: #d0def3; }
    .slick-header-menu .filter .vrow label {
      display: block;
      /*line-height: 1;*/
      margin: 0px 2px;
      height: 17px; }
    .slick-header-menu .filter .vrow label input[type=checkbox] {
      margin-bottom: 0;
      vertical-align: middle;
      margin-right: 3px; }

.slick-header-menu button:not(:last-child) {
  margin-right: 25px; }

/* Disabled */
.slick-header-menuitem-disabled {
  color: silver; }

.header-overlay {
  background-color: #5292F7; }

.grid-pager {
  width: 100%;
  /*height: 20px;*/ }

.slick-pager {
  font-size: small;
  /*original - start*/
  width: 100%;
  height: 26px;
  border: 1px solid #D6D7D6;
  /*border-top: 0;*/
  /*background: url('../images/header-columns-bg.gif') repeat-x center bottom;*/
  background-color: #e1e1e1;
  vertical-align: middle;
  /*
    .slick-pager-status {
        display: inline-block;
        padding: 6px;
    }

    .ui-icon-container {
        display: inline-block;
        margin: 2px;
        border-color: gray;
    }

    .slick-pager-nav {
        display: inline-block;
        float: left;
        padding: 2px;
    }

    .slick-pager-settings {
        display: block;
        float: right;
        padding: 2px;
    }

    .slick-pager-settings * {
        vertical-align: middle;
    }

    .slick-pager-settings a {
        padding: 2px;
        text-decoration: underline;
        cursor: pointer;
    }
    */
  /*original - end*/ }
  .slick-pager .slick-pager-left {
    padding: 3px;
    float: left; }
  .slick-pager .slick-pager-filter-text {
    display: block;
    overflow: hidden;
    margin-right: 3px;
    vertical-align: middle; }
  .slick-pager .slick-pager-filter-text input {
    width: 100%;
    box-sizing: border-box;
    padding: 0 3px;
    vertical-align: middle; }

/*
.grid-header {
    border: 1px solid gray;
    border-bottom: 0;
    border-top: 0;
    background: url('../../assets/X-SlickGrid/images/header-bg.gif') repeat-x center top;
    color: black;
    height: 24px;
    line-height: 24px;
    width: 100%
}

.grid-header label {
    display: inline-block;
    font-weight: bold;
    margin: auto auto auto 6px;
}

.grid-header .ui-icon {
    margin: 4px 4px auto 6px;
    background-color: transparent;
    border-color: transparent;
}

.grid-header .ui-icon.ui-state-hover {
    background-color: white;
    background: none;
}

.grid-header #txtSearch {
    margin: 0 4px 0 4px;
    padding: 2px 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid silver;
}

.options-panel {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border: 1px solid silver;
    background: #f0f0f0;
    padding: 4px;
    margin-bottom: 20px;
    width: 320px;
    position: absolute;
    top: 0px;
    left: 650px;
}
*/
.flatpickr-wrapper {
  position: absolute; }

/*Select2*/
.select2-container {
  top: -2px;
  left: -2px;
  font-size: 12px; }

.select2-container .select2-selection--single {
  height: 23px;
  /*margin-left: 3px;
        margin-top: 2px;*/
  background-color: inherit; }

.select2-container--default .select2-selection--single {
  /*background-color: rgb(236, 248, 236);*/
  border: none;
  /*1px solid #aaa;*/
  border-radius: 0;
  /*4px;*/
  outline: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 25px;
  color: #555; }

.select2-container--default .select2-selection--multiple {
  /*background-color: rgb(236, 248, 236);*/
  border-radius: 0;
  /*4px;*/
  border-color: gray;
  border-width: 0.5px;
  margin-left: 2px;
  margin-top: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border: none;
  margin-right: 4px;
  margin-top: 4px; }

.select2-container--default .select2-selection--multiple {
  margin: 2px 4px 0 -1px; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 2px;
  padding-top: 0;
  margin-top: -2.5px; }

.select2-container--open .select2-dropdown--below {
  left: -2px;
  top: 2px; }

.select2-container--open .select2-dropdown--above {
  top: -4px;
  left: -2px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: -5px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-left: -6px; }

.select2-results__option {
  padding: 4px;
  border-bottom: 1px solid white; }

.select2-results__option .wrap:before {
  font-family: FontAwesome;
  color: royalblue;
  /*#999;*/
  content: "\f096";
  width: 25px;
  height: 25px;
  padding-right: 10px; }

.select2-results__option[aria-selected=true] .wrap:before {
  content: "\f14a"; }

.loading-indicator {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 12px;
  background: white;
  /*opacity: 0.5;*/
  color: black;
  font-size: smaller;
  font-weight: bold;
  z-index: 9999;
  border: 1px solid red;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-box-shadow: 0 0 5px red;
  -webkit-box-shadow: 0px 0px 5px red;
  -text-shadow: 1px 1px 1px white; }

.loading-indicator label {
  margin: 0;
  padding-left: 20px;
  background: url("../images/ajax-loader-small.gif") no-repeat center left; }

nav.grid-navbar .container {
  width: 100%;
  padding: 0px; }

nav.grid-navbar .navbar-collapse.collapse {
  padding-right: 0px; }

nav.grid-navbar .grid-toolbar.navbar-form.navbar-right {
  margin-right: 0px;
  padding-right: 0px; }

nav.grid-navbar .grid-toolbar .dropdown-menu input {
  width: 98%;
  margin: -5px auto 0;
  display: block; }

nav.grid-navbar .grid-toolbar .dropdown-menu .dropdown-menu-list {
  overflow-y: auto;
  max-height: 401px;
  margin-top: 2px;
  margin-bottom: -7px; }

nav.grid-navbar label input[type=checkbox] {
  vertical-align: text-bottom;
  margin-right: 2px; }

nav.grid-navbar button .glyphicon {
  margin-right: 3px; }

.badge {
  margin-left: 4px; }

.popover-error .popover-body {
  max-height: 510px;
  overflow-y: auto; }

.popover-favorites .form-control.is-valid,
.popover-favorites .was-validated .form-control:valid,
.popover-favorites .form-control.is-invalid,
.popover-favorites .was-validated .form-control:invalid {
  background-image: none; }

.btn-favorites-save,
.btn-favorites-remove {
  font-size: .75rem; }
