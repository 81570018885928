/* UserEdit.css */

.modal-dialog .modal-body .permissions-section {
    /*height: 347px;
    overflow-y: auto;*/
    position: relative;
    padding-top: 25px;
    background-color: lightgray;
    margin: 1px 5px;
}

.modal-dialog .modal-body .form-group.permissions-container {
    overflow-y: auto;
    height: 273px;
    position: static;
    background-color: white;
    margin-bottom:0;
}

/* header */
.permissions-section th {
    height: 0;
    line-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: transparent;
    border: none;
    white-space: nowrap;
}
    .permissions-section th div {
        position: absolute;
        background: transparent;
        color: black;
        padding: 12px 25px;
        top: 0;
    }

/* body */
.permissions-section tbody tr {
    border-bottom: 0.5px solid lightgray;
}
    .permissions-section tbody tr td {
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
    }

.searchable-select {
    margin-top: 4px;
}

.searchable-select input {
    /*border: none;*/
    z-index: 100;
    position: relative;
    width: calc(100% - 30px);
    top: -2px;
}

.searchable-select select {
    position: relative;
    margin-top: -30px;
    cursor: pointer;
    /* remove the original arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /* no standardized syntax available, no ie-friendly solution available */
}
    /*IE*/
    .searchable-select select::-ms-expand {
        display: none;
    }

.searchable-select i {
    float: right;
    margin-top: -22px;
    margin-right: 8px;
    /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
    pointer-events: none;
    z-index: 100;
    position: relative;
    font-size: medium;
    /* everything after this is just to cover up the original arrow */
    /* (for browsers that don't support the syntax used above) */
    /*background-color: #fff;
        padding-right: 5px;*/
}
