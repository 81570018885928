/*index.css*/

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

@media (max-width: 1300px) {
    /* Hide caption when screen is less than 1300px.*/
    .grid-toolbar button:not([data-filter-order]) span.caption:not(.always-visible) {
        display: none;
    }
}

/* stelian - start */
.container {
    padding-left: 2px;
    padding-right: 2px;
    /*margin-top: 1rem;*/
    max-width: 100%;
}

.modal-dialog {
    margin: auto;
}

i.fa {
    margin-right: 3px;
    margin-left: 3px;
}

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
    height: 1.8125rem; /*calc(1.8125rem +2px)*/
}

/* stelian - end */


/* app.loader - start */

.loadContainer {
    position: fixed;
    width: 150px;
    height: 150px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}

    .loader:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 12px solid #fff;
        position: absolute;
        top: 0;
        left: 0;
    }

    .loader > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .loader .loader-left {
        left: 0;
    }

    .loader .loader-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 12px;
        border-style: solid;
        position: absolute;
        top: 0;
    }

    .loader .loader-left .loader-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
    }

    .loader .loader-right {
        right: 0;
    }

        .loader .loader-right .loader-bar {
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 2s linear forwards infinite;
        }

    .loader .loader-value {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background: #44484b;
        font-size: 24px;
        color: #fff;
        line-height: 135px;
        text-align: center;
        position: absolute;
        top: 5%;
        left: 5%;
    }

    .loader .loader-bar {
        border-color: #049dff;
    }

    .loader .loader-left .loader-bar {
        animation: loading-2 2s linear forwards infinite;
    }

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

/* app.loader - end */

/* fixing active tab for reacstrap */
#myForm .nav-tabs .nav-item {
    cursor: pointer;
    border-left-color: #dee2e6;
    border-right-color: #dee2e6;
    border-top-color: #dee2e6;
    border-top: 1px solid;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-left: 1px solid;
    border-right: 1px solid;
}

    #myForm .nav-tabs .nav-item.show .nav-link,
    #myForm .nav-tabs .nav-item.active {
        color: #495057;
        background-color: #6ed3cf;
    }


#myForm .tab-content {
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

    #myForm .tab-content .tab-pane.active {
        padding: 10px;
        background-color: #6ed3cf;
    }


.computeSet {
    color: #495057;
    background-color: white !important;
}

    .computeSet.active {
        color: #495057;
        background-color: #e1e8f0 !important;
    }

.ilbSet {
    color: #495057;
    background-color: white !important;
}

    .ilbSet.active {
        color: #495057;
        background-color: #f1f0ee !important;
    }

.healthProbeRules {
    background-color: gainsboro;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
}

#myForm .applicationSet {
    color: #495057;
    background-color: #e1e8f0 !important;
    border: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}

#myForm .applicationDBSet {
    color: #495057;
    background-color: #f1f0ee !important;
    border: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
}

#myForm .alertMessage {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

/*.btnForm {
    margin: 0 15px;
}

.whiteBoard {
    background-color: white;
}
.grayBoard {
    background-color: #cccccc;
    border:1px solid #000;
}

legend {
    padding: 5px 0 5px 5px !important;
    font-weight: bolder !important;
}

label {
    font-weight: normal !important;
}*/
/*.quarter {
    width: 23% !important;
    margin: 0 4px 0 4px;
    display: inline-block;
    vertical-align: top;
}

.third {
    width: 33% !important;
    margin: 0 4px 0 4px;
    vertical-align: top;
}

.half {
    width: 45%;
    margin: 0 4px 0 4px;
    display: inline-block;
}

.mid {
    width: 70%;
    margin: 0 4px 0 4px;
    display: inline-block;
    vertical-align: top;
}

.full {
    width: 100%;
    margin: 0 4px 0 4px;
    vertical-align: top;

}*/
/*.environments {
    padding: 3px;
    width: 100%
}

.Title {
    font-weight: bolder;
    font-size: 17px;
}

.Center {
    text-align: center;
}

.tableTD {
    vertical-align: top;
    border: 1px;
    border-color: gray;
}*/
/*.environmentsContent {
    box-shadow: 1px 1px 1px #215b7d;
    border: 1px;
    border-style: solid;
    border-color: #215b7d;
    margin: 40px 0 0 10px;
    padding: 10px;
    width: 130%;
}

.enviromentsCompute {
    margin: 1%;
    box-shadow: 1px 1px 1px #215b7d;
    border: 1px;
    border-style: solid;
    border-color: #215b7d;
    background-color: #ccc;
}

.enviromentsStorage {
    margin: 0 1% 0 1%;
    background-color: white;
}

.computeAvailabilitySet {
    box-shadow: 1px 1px 1px #ccc;
    border: 1px;
    border-style: solid;
    border-color: #215b7d;
    margin: 40px 0 0 10px;
    padding: 5px 10px 0 10px;
    width: 130%;
    background-color: white;
}

.disks {
    box-shadow: 1px 1px 1px #ccc;
    border: 1px;
    border-style: solid;
    border-color: #215b7d;
    margin: 40px 0 0 5px;
    padding: 5px 10px 0 10px;
}

.loadBalancer {
    box-shadow: 1px 1px 1px #ccc;
    border: 1px;
    border-style: solid;
    border-color: #215b7d;
    margin: 40px 0 0 5px;
    padding: 5px 10px 0 10px;
}

.healthProbe {
    background-color: #CCCCCC;
    padding-left: 3px;
    width: 100%
}
    
.white {
    background-color: white;
    padding: 0 15px 0 15px;
    float: left;
    width: 100%;
}*/
.radioInline {
    display: inline-block !important;
    margin-left: 25px !important;
}

.checkBoxMiddleLine {
    margin-left: 0;
}


/* preview Start */
.PreviewDiv {
    overflow-x: scroll;
}

.PreviewTableFixedColumn {
    position: absolute;
    top: auto;
    width: 120px;
    background-color: white;
}

.PreviewTable {
    border: 1px;
    border-color: black;
    border-style: solid;
}

.PreviewTabe tbody td {
    white-space: nowrap;
}

.PreviewTable td {
    padding-left: 5px;
    padding-right: 5px;
}

.borderRightTable {
    border-right: 1px;
    border-color: black;
    border-right-style: solid;
}

.Modal {
    font-size: 12px !important;
    /*position: fixed;
    z-index: 1999;
    background-color: white;
    width: 98%;
    border: 2px solid #000;
    box-shadow: 2px 2px 2px black;
    padding: 16px;
    left: 5%;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow: scroll;*/
}

@media (min-width: 600px) {
    .Modal {
        width: 98%;
        max-width: 98% !important;
    }

    .modal-lg {
        width: 95% !important;
    }

    .modal-xl {
        max-width: 95% !important;
    }
}





.zui-table {
    border: none;
    border-right: solid 1px #DDEFEF;
    border-collapse: separate;
    border-spacing: 0;
}

    /*.zui-table thead th {
        background-color: #DDEFEF;
        border: none;
        color: #336B6B;
        padding: 10px;
        text-align: left;
        text-shadow: 1px 1px 1px #fff;
        white-space: nowrap;
    }*/

    .zui-table tbody td {
        border-bottom: solid 1px #DDEFEF;
        color: #333;
        padding: 10px;
        text-shadow: 1px 1px 1px #fff;
        /*white-space: nowrap;*/
    }

.zui-wrapper {
    position: relative;
}

.zui-scroller {
    margin-left: 126px;
    overflow-x: scroll;
    overflow-y: visible;
    /*padding-bottom: 5px;*/
    /*width: 300px;*/
}

.zui-table .zui-sticky-col {
    border-left: solid 1px #DDEFEF;
    border-right: solid 1px #DDEFEF;
    left: 0;
    position: absolute;
    top: auto;
    height: auto;
    width: 135px;
    font-weight: bold;
    background-color: white;
}

.is-invalid {
    border-color: #dc3545;
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right calc(2.25rem / 4);
    background-size: calc(2.25rem / 2) calc(2.25rem / 2);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}

#myForm .form-control.is-valid,
#myForm .was-validated .form-control:valid,
#myForm .form-control.is-invalid,
#myForm .was-validated .form-control:invalid {
    background-image: none;
}


.row {
    margin-right: -5px !important;
}
