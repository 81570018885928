/*plugins-common.css*/
.slick-header-menubutton
{
    background-position: center center;
    background-repeat: no-repeat;
    border-left: thin ridge silver;
    bottom: 0;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
}

.slick-header-menu
{
    background: none repeat scroll 0 0 white;
    border: 1px solid #BFBDBD;
    min-width: 175px;
    padding: 4px;
    z-index: 100000;
    cursor: default;
    display: inline-block;
    margin: 0;
    position: absolute;
}

    .slick-header-menu BUTTON {
        /*border: 1px solid #BFBDBD;
        background-color: White;
        width: 45px;
        padding: 4px;
        margin: 4px 4px 4px 0;*/
        padding: .25rem .4rem;
        margin: 4px 0;
        font-size: .725rem;
        line-height: .7;
        border-radius: .2rem;
    }

/*
.slick-header-menu .filter
{
    border: 1px solid #BFBDBD;
    font-size: 8pt;
    height: 400px;
    margin-top: 6px;
    overflow: scroll;
    padding: 4px;
    white-space: nowrap;
    width: 200px;
}
*/

label
{
    display: block;
    margin-bottom: 5px;
}

.slick-header-menuitem
{
    border: 1px solid transparent;
    padding: 2px 4px;
    cursor: pointer;
    list-style: none outside none;
    margin: 0;
}

.slick-header-menuicon
{
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
    width: 16px;
}

.slick-header-menucontent
{
    display: inline-block;
    vertical-align: middle;
}

.slick-header-menuitem:hover
{
    border-color: #BFBDBD;
}

.header-overlay, .cell-overlay, .selection-cell-overlay
{

    display: block;
    position: absolute;
    z-index: 999;
}


