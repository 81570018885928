/* UploadModal.css */

#uploadModal .modal-dialog {
    max-width:500px;
}

.popover-error-import {
    min-width: 370px;
    max-width: 370px;
    margin-left: -175px;
}

    .popover-error-import .popover-content {
        max-height: 510px;
        overflow-y: auto;
    }

#isSSoTFullSync {
    margin-right: 4px;
    vertical-align: top;
}